<template>
  <header id="page-topbar">
    <!-- Header element with the ID "page-topbar" -->
    <div class="navbar-header">
      <!-- Navbar header container -->
      <div class="d-flex text-center">
        <!-- Create a flex container for the logo and menu button -->
        <div class="navbar-brand-box bg-white">
          <!-- Navbar brand box for the application logo -->
          <div class="logo logo-dark">
            <!-- Dark version of the logo -->
            <router-link to="/">
              <span class="logo-sm">
                <!-- Small logo for mobile view -->
                <img
                  src="@/assets/images/metr-logo.svg"
                  alt
                  class="logo-image-sm"
                />
              </span>
              <span class="logo-lg">
                <!-- Large logo for desktop view -->
                <img
                  src="@/assets/images/metr-logo.svg"
                  alt
                  class="logo-image-lg"
                />
              </span>
            </router-link>
          </div>
          <div class="logo logo-light bg-white">
            <!-- Light version of the logo -->
            <router-link to="/">
              <span class="logo-sm">
                <img
                  src="@/assets/images/metr-logo.svg"
                  alt
                  class="logo-image-sm"
                />
              </span>
              <span class="logo-lg">
                <img
                  src="@/assets/images/metr-logo.svg"
                  alt
                  class="logo-image-lg"
                />
              </span>
            </router-link>
          </div>
        </div>
        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn"
        >
          <!-- Button to toggle the vertical menu -->
          <i class="ri-menu-2-line align-middle"></i>
        </button>
      </div>
      <div class="d-flex">
        <!-- Create a flex container for user-related actions -->
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <!-- Dropdown for fullscreen mode -->
          <button
            type="button"
            class="btn btn-sm header-item noti-icon waves-effect"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <!-- Dropdown for user actions -->
          <template v-slot:button-content>
            <!-- Template for the dropdown button content -->
            <span class="d-xl-inline-block ml-1">
              {{ $store.state.Auth.adminName }}</span
            >
            <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
          </template>
          <router-link class="dropdown-item" to="/profile">
            <i class="ri-user-line align-middle mr-2"></i>
            <span>{{ $t("navbar.dropdown.kevin.list.profile") }}</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" @click="logOut">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.kevin.list.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
// import { logoutApplication } from "../api/logout";
import Swal from "sweetalert2";

import i18n from "../i18n";
export default {
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
      current_language: "en",
    };
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    logOut() {
      // logoutApplication(this.$store.state.Auth.userId);
      Swal.fire({
        title: "Logout",
        text: "Do you want to logout the application?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Logout",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.getters.client.post(`/logout`);
          localStorage.clear();
          window.location.href = "/";
        }
      });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale) {
      i18n.locale = locale;
      this.current_language = i18n.locale;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-image-sm {
  width: 36px;
  height: auto;
}
.logo-image-lg {
  width: 4rem;
  height: auto;
}
.logo-text {
  font-size: 13px;
  color: white;
}
a {
  cursor: pointer;
}
</style>
